<!--
목적 : 도급업체 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customVendor"
      class="customVendor"
      :dense="dense"
      stack-label
      bottom-slots
      color="orange-7"
      :label="label"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{label}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <template v-if="!editing">
          {{valueText}}
        </template>
        <template v-else>
          <input class="col-12 custom-editing-input" 
            ref="custom-editing-input"
            type="text"
            v-model="valueText"
            @input="datachange" />
        </template>
      </template>

      <template v-slot:append>
        <div v-if="!disabled&&editable&&selfFlag" class="vendor-self cursor-pointer" @click="self" >자체</div>
        <q-icon v-if="!disabled&&editable&&isWrite" name="edit" @click="edit" 
          class="cursor-pointer"
          :class="[editing ? 'text-red' : '']"></q-icon>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'c-vendor',
  props: {
    name: {
      type: String,
      default: 'field',
    },
    data: {
      type: Object,
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'vendor',
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    selfFlag: {
      type: Boolean,
      default: true,
    },
    isWrite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      editing: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
    'data.vendorName'() {
      this.setValueText();
    },
    editing() {
      if (this.editing) {
        this.$refs['customVendor'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.search);
      } else {
        this.$refs['customVendor'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
      }
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customVendor'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customVendor'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        let list = this.$store.state.systemInfo.vendors;
        let vendor = this.$_.find(list, { vendorCd: this.vValue });
        this.valueText = vendor.vendorName;
      } else {
        if (this.data) {
          this.valueText = this.data['vendorName'] || '';
          this.editing = true;
        } else {
          this.valueText = '';
          this.editing = false;
        }
      }
    },
    edit() {
      /**
       * key-in
       */
      if (!this.editing) {
        this.$set(this.$data, 'editing', true)
        // this.editing = true;
        setTimeout(() => {
          this.$refs['custom-editing-input'].focus();
        }, 1000);
      } else {
        this.editing = false;
      }
      this.$emit('input', null);
      this.valueText = '';
    },
    search() {
      this.editing = false;
      this.popupOptions.title = '도급업체 검색'; // 사용자 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let beforeVendorCd = this.$_.clone(this.vValue);
        if (this.type === 'vendor') {
          this.valueText = data[0].vendorName;
        }
        this.$emit('dataChange', data[0].vendorCd, beforeVendorCd);
        this.$emit('username', data[0].vendorName);
        this.$emit('input', data[0].vendorCd);
      }
    },
    reset() {
      this.editing = false;
      let beforeVendorCd = this.$_.clone(this.vValue);
      this.valueText = '';
      this.$emit('dataChange', null, beforeVendorCd);
      this.$emit('input', null);
      this.$emit('change', null);
    },
    self() {
      // 직영으로
      this.editing = false;
      let beforeVendorCd = this.$_.clone(this.vValue);
      this.valueText = '자체';
      this.$emit('dataChange', 'VEN0000000', beforeVendorCd, { vendorCd: 'VEN0000000', vendorName: '자체' });
      this.$emit('input', 'VEN0000000');
      this.$emit('vendorName', '자체');
    },
    datachange() {
      this.$set(this.data, 'vendorName', this.valueText)
      this.$emit('dataChange', this.valueText);
    },
  },
};
</script>
<style lang="sass">
.customVendor
  .q-field__native
    .custom-editing-input
      line-height: 18px !important
      padding: 0 !important
      border: 0
    .custom-editing-input:focus
      border: 0 !important
      outline: none
</style>

<!-- <style lang="sass">
.customVendor
  margin-bottom: 0px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 1.05em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customVendor:hover
  .dateCloseIcon
    display: block
.customVendor.q-field--dense .q-field__control, .customVendor.q-field--dense .q-field__marginal
  height: 38px !important
  min-height: 38px !important
.customVendor.q-field--filled .q-field__control
  border-bottom: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
</style> -->