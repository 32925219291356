<template>
  <div>
    <CurvedBottomNavigation 
      :options="options" 
      v-model="selected" 
      @callbackSearchAction="searchAreaToggle"
      @callbackMenuAction="menuToggle"
      @callbackSwpAction="swpToggle"
      @callbackAction="callbackAction" />
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>

export default {
  name: "Footer",
  computed: {
    changeSearchAreaShow() {
      return this.$store.state.app.searchAreaToggle;
    },
    isMain() {
      return this.$route.name === 'main' || this.$route.path === '/' || this.$route.path === '/main/index';
    },
    isMenuTitle() {
      let data = this.$_.find(this.$store.getters.addRouters, { name: this.$route.name });
      return data ? true : false;
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    changeSearchAreaShow() {
      this.searchAreaShow = this.$_.clone(this.changeSearchAreaShow)
    },
    $route(to, from) {
      if (to.path != from.path) {
        this.getMenuBottom();
      }
    }
  },
  data() {
    return {
      searchAreaShow: false,
      apprCnt: null,
      selected: 1,
      options: [],
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  methods: {
    init() {
      this.getMenuBottom();
    },
    searchAreaToggle() {
      this.searchAreaShow = true;
      this.$store.dispatch('searchAreaToggle', this.searchAreaShow);
    },
    menuToggle() {
      const el = document.body;
      let className = 'closed-sidebar-open'
      if (el.classList.contains(className)) {
        el.classList.remove(className);
      } else {
        el.classList.add(className);

        let _leftMenu = document.getElementsByClassName('left-sidebar-menu')
        if (_leftMenu && _leftMenu[0]) {
          _leftMenu[0].focus();
        }
      }
    },
    swpToggle(row) {
      this.popupOptions.title = '작업허가서';
      this.popupOptions.param = {
        sopWorkPermitId: row ? row.sopWorkPermitId : '',
        permitTypeCd: 'SPT0000001',
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/swp/safeWorkPermitDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    callbackAction(_type) {
      if (_type == 'MENU000398') { // 협력업체 점검
        this.popupOptions.title = '협력업체 점검 상세'; // 협력업체 점검 상세
        this.popupOptions.param = {
          plantCd: null,
          saiPatrolId: '',
          patrolTypeCd: 'PTC0000001'
        };
        this.popupOptions.target = () => import(`${"@/pages/sai/patrol/patrolDetail.vue"}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else if (_type == 'MENU100291') { // 관리감독자 점검일지
        this.popupOptions.title = '관리감독자 점검일지 상세'; // 관리감독자 점검일지
        this.popupOptions.param = {
          plantCd: null,
          saiPatrolId: '',
          patrolTypeCd: 'PTC0000002'
        };
        this.popupOptions.target = () => import(`${"@/pages/sai/patrol/patrolDetail.vue"}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else if (_type == 'MENU100272') { // TBM 상세
        this.popupOptions.title = 'TBM 상세'; // TBM 상세
        this.popupOptions.param = {
          tbmId: '',
        };
        this.popupOptions.target = () => import(`${"@/pages/sai/tbm/tbmDetail.vue"}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else if (_type == 'MENU000399') {
        this.popupOptions.title = '개선 상세'; // 개선 상세
        this.popupOptions.param = {
          sopImprovementId: '',
          ibmTaskTypeCd: 'ITT0000180',
          ibmTaskUnderTypeCd: 'ITTU000240',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else if (_type == 'MENU100284') {
        this.popupOptions.title = '제안의견 등록'; // 개선 상세
        this.popupOptions.param = {
          boardId: '',
        };
        this.popupOptions.target = () => import(`${'@/pages/sai/sob/safOpinionBoardDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } 
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('closePopFooter')
      // this.$router.go(this.$router.history.current)
    },
    getMenuBottom() {
      if (this.isMain) {
        this.options = [
          {
            id: 1,
            icon: "home",
            title: "HOME",
            path: "/",
            move: true,
          },
          { 
            id: 4, 
            icon: "qr_code", 
            title: "QR코드",
            path: "/sys/appr/qrreader",
            move: true,
          },
          { 
            id: 7, 
            icon: "menu", 
            title: "MENU",
            types: 'MENU',
            move: false,
          },
        ]
        this.selected = 1;
      } else if (!this.isMain && this.isMenuTitle) {
        if (this.$route.name == 'MENU000410') {
          this.options = [
            {
              id: 1,
              icon: "home",
              title: "HOME",
              path: "/",
              move: true,
            },
            { 
              id: 4, 
              icon: "qr_code", 
              title: "QR코드",
              path: "/sys/appr/qrreader",
              move: true,
            },
            { 
              id: 7, 
              icon: "menu", 
              title: "MENU",
              types: 'MENU',
              move: false,
            },
          ]
          this.selected = 4;
        } else {
          // let _child = [];
          let _types = null;
          let _icons = 'add';
          // if (this.$route.name == 'MENU000395') { // 작업허가서
          //   _child = [
          //     { id: 101, types: 'SWP1', icon: "handyman", title: "일반", color: 'bg-amber-7' },
          //     { id: 102, types: 'SWP2', icon: "local_fire_department", title: "화기", color: 'bg-red-6' }
          //   ];
          // } else 
          if (this.$route.name == 'MENU100272' 
            || this.$route.name == 'MENU000395' 
            || this.$route.name == 'MENU000398' 
            || this.$route.name == 'MENU100291'
            || this.$route.name == 'MENU100284'
            || this.$route.name == 'MENU000399') { 
            // TBM / 순회점검 / 작업허가서 / 제안의견
            _types = this.$route.name;
          } else {
            _icons = 'do_not_disturb_alt'
          }
          this.options = [
            {
              id: 1,
              icon: "home",
              title: "HOME",
              path: "/",
              move: true,
            },
            { 
              id: 4, 
              icon: "qr_code", 
              title: "QR코드",
              path: "/sys/appr/qrreader",
              move: true,
            },
            {
              id: 3,
              icon: _icons,
              title: "",
              // childs: _child,
              types: _types,
              move: false,
            },
            { 
              id: 7, 
              icon: "menu", 
              title: "MENU",
              types: 'MENU',
              move: false,
            },
            { 
              id: 5, 
              icon: "search", 
              title: "검색조건",
              types: 'SERACH',
              move: false,
            },
          ]
          this.selected = 3;
        }
      }
    },
  }
}
</script>