import request from '@/utils/request';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import {token} from '@/fcmMessage.js'

export function sso() {
  return request({
    url: transactionConfig.sso.insert.url,
    method: 'post',
  });
}

export function loginByUserAccount(_userId, _userPwd, _userOtpPass) {
  const data = {
    loginId: _userId,
    loginPwd: _userPwd,
    userOtpPass: _userOtpPass,
    fcmToken: token,
    deviceType: 'M',
  };
  return request({
    url: transactionConfig.auth.login.insert.url,
    method: 'post',
    data,
  });
}

export function logout() {
  return request({
    url: transactionConfig.auth.login.logout.url,
    method: 'post',
  });
}

export function getUserInfo(token) {
  return request({
    url: selectConfig.auth.userInfo.url,
    method: 'get',
    params: {
      token,
      deviceTypeCd: 'P',
    },
  });
}

export function updateTokenByRefreshToken() {
  return request({
    url: selectConfig.auth.login.refresh.url,
    method: 'get',
  });
}

export function validToken() {
  return request({
    url: selectConfig.auth.login.valid.url,
    method: 'get',
  });
}